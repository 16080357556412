import { v4 as uuidv4 } from 'uuid';

declare global {
  interface Window { nid: any; }
}

export default  (siteId, production) => {
  let identityId;
  sessionStorage.setItem('neuroIdOpen', 'true');

  if (!sessionStorage.getItem('identityId')) {
    identityId =  uuidv4();
    sessionStorage.setItem('identityId', identityId);
  } else {
    identityId = sessionStorage.getItem('identityId')
  }
  // slice 'form_' off the siteId
  const siteIdString =  production ? siteId.slice(5) : `${siteId}-test`.slice(5);
  
  // Create script
  const script: HTMLScriptElement = document.createElement('script');
  script.type = 'text/javascript';
  script.src = `//scripts.neuro-id.com/c/nid-${siteIdString}.js`;
  script.async = true;

  window.nid = window.nid || function (...args) {
    (window.nid.q = window.nid.q || []).push(args);
  };
  // Add script to document 
  document.head.appendChild(script);
  
  window.nid('identify', identityId);
  return identityId
}

export const removeNeuroId = () => {
  // creates a check to avoid calling closeSession more than once
  if (sessionStorage.getItem('neuroIdOpen') === "true" && window.nid) {
    window.nid('closeSession')
    sessionStorage.setItem('neuroIdOpen', 'false')
  }
}


