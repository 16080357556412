import FetchError from './errors';
import config from '../config';

const COMMON_HEADERS = {
  'X-Alloy-Sdk-Platform': 'Web',
  'X-Alloy-Sdk-Version': '1',
  'Content-Type': 'application/json',
};

function isWeb() {
  return typeof document !== 'undefined'
};

export default async function commonFetch<T>({
  url,
  method,
  headers,
  fetchOptions,
  data,
}: {
  url: string;
  method: RequestInit['method'];
  headers?: RequestInit['headers'];
  fetchOptions?: Omit<RequestInit, 'method' | 'headers'>;
  data?: Record<string, unknown>;
}): Promise<T> {
  const options: RequestInit = {
    method,
    headers: {
      ...COMMON_HEADERS,
      'X-Parent-Domain': (isWeb())? document.referrer: '',
      ...headers,
    },
    ...fetchOptions,
  };
  if (method && data && ['POST', 'PUT'].includes(method)) {
    options.body = JSON.stringify(data);
  }

  const response = await fetch(`${config.api}${url}`, options);
  if (!response.ok) {
    const error = new FetchError();
    error.info = await response.json();
    error.status = response.status;
    throw error;
  }
  return response.json();
}
