export default class FetchError extends Error {
  name = 'FetchError';

  info?: Record<string, unknown>;

  status?: number;

  constructor(message?: string) {
    super(message);
    this.message = message || 'An error occurred while fetching the data.';
  }
}