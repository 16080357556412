declare const devicer: any;

export default async (socurePublicToken: string) => {
  // Create script
  const script: HTMLScriptElement = document.createElement('script');
  script.src = 'https://scripts.alloy.com/devicer.min.js';
  script.type = 'text/javascript';
  script.async = true;
  script.setAttribute('data-status', 'loading');
  // Add script to document body
  document.head.appendChild(script);

  // create promise to await get device ID
  const socureDeviceId = await new Promise<string>(resolve => {
    // get device Id from devicer library
    const getDeviceId = () => {
      // remove listener after enter the first time
      script.removeEventListener('load', getDeviceId);
      const deviceFPOptions = {
        publicKey: socurePublicToken,
        userConsent: true,
        endpoint: 'device.alloy.com',
        context: 'homepage',
      };
      try {
        // get device ID
        devicer.run(deviceFPOptions, response => {
          resolve(response.sessionId);
        });
      } catch (err) {
        resolve('');
      }
    };

    // wait to script to be fully loaded
    script.addEventListener('load', getDeviceId);
  });
  return socureDeviceId;
}
